@import "./variables.scss";
@import "./breakpoints.scss";

.footer {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  gap: 50px;
  padding: 10px 70px;
  background-color: #d0eeea;

  a:hover {
    cursor: pointer;
    color: #38b0a09f;
  }

  @include sm {
    flex-direction: column;

    align-items: center;
  }

  @include lg {
    padding: 10px 30px;
  }

  &_left {
    max-width: 400px;

    img {
      max-width: 150px;
      margin-bottom: -59px;
    }

    &_socials {
      display: flex;
      gap: 25px;
      margin-top: 20px;

      &_icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #7ee4d7;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.5s ease-in-out;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }

  &_center {
    text-decoration: none;

    @include sm {
      text-align: center;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style: none;
      margin-top: 10px;
      cursor: pointer;

      a {
        text-decoration: none;
        color: black;
        transition: all 100ms ease-in;
        // &:hover {
        //   cursor: pointer;
        //   color: #38b0a09f;
        // }
      }

      .socials {
        display: flex;
        gap: 1rem;

        a {
          transition: all 300ms ease-in-out;
          font-size: 1.3rem;
        }

        a:hover {
          transform: scale(0.8);
        }

        @include sm {
          justify-content: center;
        }

        .facebook:hover {
          color: #0866ff;
        }
        .instagram:hover {
          color: #c53480;
        }
        .linkedIn:hover {
          color: #0a66c2;
        }
      }
    }

    @include md {
      // display: none;
      // flex-direction: column;
    }
  }

  &_right {
    max-width: 350px;

    h3 {
      margin-bottom: 15px;
      @include sm {
        text-align: center;
      }
    }

    &_info {
      display: flex;

      p {
        margin-left: 10px;
        margin-bottom: 15px;
      }
    }

    @include sm {
      // display: none;
      flex-direction: column;
      align-items: center;
    }
  }

  .payment-options {
    h3 {
      margin-bottom: 10px;
    }

    @include sm {
      text-align: center;
    }
  }
}

.footer_right {
  .footer-call {
    p:hover {
      cursor: pointer;
      color: rgb(0, 0, 0, 0.6);
    }
  }
}

@media screen and (min-width: 431px) and (max-width: 1000px) {
  .footer {
    display: flex;
    flex-wrap: wrap;
  }
}
