.nav-carousel-wrapper {
  position: relative;
}

.nav-carousel {
  position: unset;
}

.carousel {
  display: flex;
  width: 75rem;
  margin-left: 10rem;
}

.react-multiple-carousel__arrow {
  margin: -0.3125rem 3rem 0 3rem;
  z-index: 1;
  background: none;
}

.react-multiple-carousel__arrow:hover {
  background: #67c7b9;
}

.react-multiple-carousel__arrow::before {
  color: black;
}

/* Carousel Arrow */
.react-multiple-carousel__arrow:hover::before {
  color: white;
}

/* Style when disabled */
.react-multiple-carousel__arrow[disabled] {
  background-color: #b7b4b4;
}

.react-multiple-carousel__arrow[disabled]::before {
  color: white;
}

.react-multiple-carousel__arrow[disabled]:hover {
  background-color: #b7b4b4;
}

.carousel-button-group {
  position: absolute
}

.slider {
  padding: 1rem 0;
}

.slider:hover {
  cursor: grab;
}

.slider.grabbing {
  cursor: grabbing;
}

.lower-navbar-carousel-item {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.lower-navbar-carousel-item p {
  font-size: 14px;
}

.lower-navbar-carousel-item:hover p,
.lower-navbar-carousel-item:hover .icon {
  color: #67c7b9;
  transition: color 0.3s ease-in-out;
}

.active {
  color: #67c7b9;
}


.lower-navbar-carousel-item .icon {
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
}

@media (min-width: 350px) {

  /* Container for the next and previous buttons */
  .product-carousel-1 {
    display: flex;
    /* Display buttons in a row */
    align-items: center;
    /* Center buttons vertically */
  }

  /* Styles for previous button */
  #pre-btn-1,
  #next-btn-1 {
    cursor: pointer;
    background: none;
    background-color: transparent;
    border: none;
    outline: none;
    width: fit-content;
    /* Change cursor to pointer on hover */
  }

  /* Styles for the button icon */
  #pre-btn-1 img,
  #next-btn-1 img {
    width: 22px;
    /* Set width of the icon */
    transform: scaleX(-1);
    /* Flip icon horizontally for previous button */
  }
}

@media only screen and (min-width: 1117px) and (max-width: 1420px) {
  .carousel {
    width: 61rem;
    margin-right: -1rem;
  }

  .react-multiple-carousel__arrow--right {
    margin-right: 3rem;
  }

  .react-multiple-carousel__arrow--left {
    margin-left: 4rem;
  }
}

@media (max-width: 768px) {

  .nav-carousel-wrapper {
    margin: 2rem 0 -2rem 0;
  }

  .carousel {
    width: 23rem;
    margin-left: -13px;
  }
}