@import "../styles/variables.scss";
@import "../styles/breakpoints.scss";

.create-listing {
  background-color: $lightgrey;
  padding: 40px 60px 120px;

  @include mm {
    padding: 40px 20px;
  }

  h1 {
    color: $blue;
  }

  &_step1 {
    background-color: white;
    padding: 30px 40px;
    border-radius: 20px;
    margin-top: 40px;

    @include sm {
      padding: 30px 20px;
    }

    h2 {
      // color: #67c7b9;
      color: #67c7b9;
    }

    hr {
      margin: 15px 0 25px;
    }

    h3 {
      margin: 40px 0 20px;
      color: $blue;
    }

    .category-list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      padding: 0 20px;

      @include lg {
        padding: 0 20px;
      }

      @include md {
        padding: 0;
      }

      .category {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 90px;
        border: 1px solid $grey;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.2s ease;

        &_icon {
          font-size: 30px;
          color: black;
        }

        p {
          font-weight: 600;
          text-align: center;
          color: black;
        }

        &:hover {
          border: 2px solid #67c7b9;
          background-color: $lightgrey;
        }
      }

      .selected {
        border: 2px solid #67c7b9;
        background-color: $lightgrey;
      }
    }

    .type-list {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 600px;
        padding: 15px 30px;
        border: 1px solid $grey;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.3s ease;

        &_text {
          max-width: 400px;

          h4 {
            margin-bottom: 5px;
          }
        }

        &_icon {
          font-size: 30px;
        }

        &:hover {
          border: 2px solid #67c7b9;
          background-color: $lightgrey;
        }
      }

      .selected {
        border: 2px solid #67c7b9;
        background-color: $lightgrey;
      }
    }

    .full {
      max-width: 700px;
    }

    .half {
      max-width: 700px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;

      @include ml {
        display: flex;
        flex-direction: column;
        gap: 0;
      }
    }

    .location {
      p {
        font-weight: 700;
        margin: 20px 0 10px;
      }
      input {
        border: 1px solid $grey;
        padding: 15px 30px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        width: 100%;

        &:focus {
          outline: none;
        }
      }
    }

    .basics {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;

      .basic {
        display: flex;
        align-items: center;
        gap: 30px;
        padding: 15px;
        border: 1px solid $grey;
        border-radius: 10px;

        p {
          font-weight: 600;
        }

        &_count {
          display: flex;
          align-items: center;
          gap: 9px;
          font-size: 20px;
        }
      }
    }
  }

  &_step2 {
    background-color: white;
    padding: 30px 40px;
    border-radius: 20px;
    margin-top: 40px;

    @include sm {
      padding: 30px 20px;
    }

    h2 {
      color: #67c7b9;
    }

    hr {
      margin: 15px 0 25px;
    }

    h3 {
      margin: 40px 0 20px;
      color: $blue;
    }

    .amenities {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .facility {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 90px;
        border: 1px solid $grey;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.2s ease;

        &_icon {
          font-size: 30px;
        }

        p {
          font-weight: 600;
        }

        &:hover {
          border: 2px solid #67c7b9;
          background-color: $lightgrey;
        }
      }

      .selected {
        border: 2px solid #67c7b9;
        background-color: $lightgrey;
      }
    }

    .photos {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      .photo {
        position: relative;
        width: 250px;
        height: 150px;
        cursor: move;

        img {
          width: 100%;
          height: 100%;
        }

        button {
          position: absolute;
          right: 0;
          top: 0;
          padding: 3px;
          border: none;
          background-color: rgba(255, 255, 255, 0.8);
          font-size: 20px;
          cursor: pointer;

          &:hover {
            color: #67c7b9;
          }
        }
      }

      label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px dashed $grey;

        .icon {
          font-size: 60px;
        }

        p {
          font-weight: 600;
          text-align: center;
        }
      }

      .together {
        width: 250px;
        height: 150px;
      }

      .alone {
        padding: 40px 100px;
        border-radius: 10px;

        @include mm {
          padding: 40px 80px;
        }

        @include sm {
          padding: 40px 30px;
        }
      }
    }

    .title {
      input {
        border: 1px solid $grey;
        padding: 15px 30px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        width: 100%;

        &:focus {
          outline: none;
        }
      }
    }

    .description {
      p {
        font-weight: 700;
        margin: 20px 0 10px;
      }

      input,
      textarea {
        border: 1px solid $grey;
        padding: 15px 30px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        width: 600px;

        @include ml {
          width: 450px;
        }

        @include md {
          width: 350px;
        }

        @include sm {
          width: 280px;
        }

        &:focus {
          outline: none;
        }
      }

      span {
        font-size: 25px;
        font-weight: 700;
        margin-right: 20px;
      }

      .price {
        width: 200px;
      }
    }
  }

  .submit_btn {
    margin-top: 40px;

    @include buttonStyle;

    &:hover {
      box-shadow: 0 0 10px 3px rgb(117, 117, 117);
    }
  }
}

.create-listing_step2 .description .price {
  width: 125px;
}

.description textarea {
  // width: 400px !important;
  // height: 200px !important;
}

.roomPrices {
  flex-direction: column;
  .room-price-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .rooms {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }
}
