.collage-container {
    padding: 0 5px;
    margin: 1rem 0;

}

.collage {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 5px;
    margin-top: 10px;
}

.collage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.collage img:nth-child(1) {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
}

.collage img:nth-child(2) {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
}

.collage img:nth-child(3) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}

.collage img:nth-child(4) {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
}

.collage img:nth-child(5) {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
}

.collage-container .collage-close-button {
    position: sticky;
    top: 30%;
    left: 2;
    z-index: 1000;
    background-color: transparent;
    background: none;
    padding: 2px 5px;
    border-radius: 100%;
    transition: all 300ms ease-in-out;
    background-color: white;
}

.collage-container .collage-close-button:hover {
    background-color: transparent;
    background: none;
}

.collage-container .collage-close-button:active {
    transform: scale(0.7);

}

.collage-close-button .fa-xmark {
    display: block;
    color: gray;
    font-size: 1.5rem;
}