.main-container {
  display: flex;
  flex-direction: column;
  .title {
    p {
      //   background-color: blue;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
  }

  .content {
    display: flex;
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
    background-color: red;
    gap: 1rem;
    justify-content: space-between;

    .booking-details {
      max-width: 40rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .container-1 {
        display: flex;
        justify-content: space-between;
        .img {
          max-width: 5rem;
          max-height: 5rem;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }

      .container-2 {
        display: flex;
        justify-content: space-between;
      }
      .container-3 {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
