@import "./variables.scss";
@import "./breakpoints.scss";

.category-list {
  padding: 50px 80px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;

  @include sm {
    padding: 50px 20px;
  }

  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $darkgrey;
    cursor: pointer;

    &_icon {
      font-size: 30px;
    }

    p {
      font-size: 18px;
      font-weight: 700;
    }

    &:hover {
      color: $pinkred;
    }
  }

  .selected {
    color: $pinkred;
  }
}

.listings {
  padding: 0 60px 120px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  @include lg {
    padding: 0 20px 120px;
  }
}

.listings {
  padding-top: 45px;
}
