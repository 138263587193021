@import "./variables.scss";
@import "./breakpoints.scss";

.login {
  width: 100vw;

  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  // background: url("../../public/assets/login.jpg") center;
  background-size: cover;
  margin-top: 5rem;
  overflow-y: hidden;
  height: 65vh;

  &_content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 40%;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    margin: auto;

    @include lg {
      width: 60%;
    }

    @include mm {
      width: 80%;
    }

    &_form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      input {
        width: 100%;
        padding: 7px 15px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        outline: none;
        text-align: center;
        color: white;

        input:not(:placeholder-shown):focus {
          background: transparent; /* or background: none; */
        }

        &::placeholder {
          color: white;
        }
      }

      .password-input {
        width: 100%;
        position: relative;

        button {
          background-color: transparent;
          box-shadow: none;
          width: fit-content;
          position: absolute;
          top: -1.4rem;
          right: -10px;

          &:hover {
            background: none;
            box-shadow: none;
          }

          @media (max-width: 576px) {
            width: fit-content;
            right: -30px;
          }

          .icon {
            @media (max-width: 576px) {
              width: 2px;
            }
          }
        }
      }

      button {
        margin-top: 15px;
        min-width: 7rem;

        @include buttonStyle;
        width: 50%;

        &:hover {
          box-shadow: 0 0 10px 3px rgba(255, 255, 255);
        }
      }
    }

    &_google {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    a {
      text-decoration: none;
      color: white;
      font-size: 17px;
      font-weight: 600;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      color: white;
      font-size: 13px;
      text-align: center;
    }
  }
}

.heading {
  display: flex;
  justify-content: center;
  color: white;
}

.reset-link {
  text-decoration: none;
  color: white;
  font-size: 17px;
  font-weight: 600;
  text-align: center;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@media (max-width: 780px) {
  .login {
    margin-top: 2rem;
  }
}
