.card {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px #eeeeee;
    padding: 3rem;
    margin-bottom: 2rem;
}

.card h2 {
    margin-bottom: 1rem;
}

@media (max-width: 780px) {
    .card {
        padding: 1rem;
    }
}