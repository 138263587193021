* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}

body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;

  ::selection {
    background-color: #d0eeea;

  }
}