.checkout-container {
  padding: 0 5rem;
  margin-bottom: 3rem;
}

.details-container {
  display: flex;
  gap: 1.5rem;
}

.details-container .card:first-child {
  width: 70%;
}

.details-container .card:last-child {
  width: 30%;
}

.hotel-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.hotel-details .hotel-title {
  font-size: 1.8rem;
}

.hotel-details img {
  width: 9rem;
}

.booking-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-details-container .checkin-details {
  display: flex;
  gap: 5rem;

}

.booking-details-container .total-days {
  box-shadow: 0 0 5px #eeeeee;
  padding: 10px;
  border-radius: 50%;
}

.booking-details-container .booking-details {
  display: flex;
  gap: 5px;
}

.guest-details {
  width: 69%;
}

.guest-details form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.guest-details input,
textarea {
  padding: 1rem;
  border-radius: 5px;
  outline: none;
  border: 1px #c1c1c1 solid;
}

.guest-details input:focus,
.guest-details textarea:focus {
  outline: black 1px solid;
}

.guest-details .input-container {
  display: flex;
  gap: 1rem;
}

.price-details-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.price-details-container .price-details {
  display: flex;
  justify-content: space-between;
}

.checkout-container .pay-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.checkout-container .pay-btn button {
  width: 30%;
}

@media (max-width: 780px) {

  .checkout-container {
    padding: 0 1rem;
    margin-bottom: 3rem;
  }

  .page-heading {
    margin-left: 3rem;
  }

  .details-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .details-container .card:first-child {
    margin-bottom: 0
  }

  .details-container .card:first-child,
  .details-container .card:last-child {
    width: 100%;
  }

  .guest-details {
    width: 100%;
  }

  .booking-details-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .booking-details-container .checkin-details {
    gap: 1rem;
  }

  .booking-details-container .total-days {
    box-shadow: 0 0 5px #eeeeee;
    font-size: 10px;
    margin: auto;
    display: flex;
    gap: 5px;
  }

  .guest-details input {
    width: 10rem;
  }

  .guest-details input:last-child {
    width: 100%;
  }

}