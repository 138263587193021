.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(118, 122, 123, 0.86);
  z-index: 1000;
  inset: 0;

  .popup-container {
    position: relative;

    .img-container {
      max-width: 60rem;
      max-height: 40rem;
      overflow: hidden;
      position: relative;
    }
    // Cross-Icon
    .fa-xmark {
      position: absolute;
      top: -1px;
      right: -2rem;
      color: white;
      transition: all 300ms ease-in-out;
      font-size: 2rem;
    }

    .fa-xmark:hover {
      color: red;
      cursor: pointer;
    }
  }

  .buttonNext {
    background-color: #67c7b9;
    margin-left: 1rem;
  }

  .buttonPrev {
    background-color: #67c7b9;
    margin-right: 1rem;
  }

  .img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .carousel-container .img-container {
    // position: relative;
    margin-top: -10rem;
    width: 95vw;
  }

  .buttonNext {
    display: none;
  }
  .buttonPrev {
    display: none;
  }

  .fa-xmark {
    display: none;
  }

  .close-btn {
    position: absolute;
    background: none;
    padding: 2px 5px;
    border-radius: 100%;
    background-color: white;
    top: 25%;
    left: 1rem;
    transition: all 100ms ease-in-out;
  }

  .close-btn:hover {
    background-color: white;
  }

  .close-btn:active {
    transform: scale(0.5);
  }

  .close-btn .fa-xmark {
    display: block;
    color: gray !important;
    font-size: 1rem;
  }
}
