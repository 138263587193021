@import "./breakpoints.scss";
@import "./variables.scss";

.title-list {
  top: 13%;
  z-index: 1000;
  color: $blue;
  text-align: center;
  background: white;

  @include sm {
    margin: 40px 50px;
  }
}
.search-title-list {
  z-index: 1000;
  color: $blue;
  text-align: center;
  background: white;

  @include sm {
    margin: 40px 50px;
  }
}

.list {
  padding: 0 100px 120px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  padding-top: 2rem;

  @include xl {
    padding: 0 20px 120px;
  }

  .no-properties-container {
    height: 7.3rem;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #67c7b9;
      font-size: 1.3rem;
      gap: 1px;
      margin-top: 1rem;
      transition: all 500ms ease-in;

      &:hover {
        gap: 10px;
      }
    }
  }

  .no-items-container {
    height: 15.5vh;

    a {
      color: #67c7b9;
      display: flex;
      align-items: center;
      margin-top: 4rem;
      gap: 2px;
      transition: all 500ms ease-in-out;
    }

    a:hover {
      gap: 10px;
    }
  }
}
