@import "./variables.scss";
@import "./breakpoints.scss";

.navbar {
  margin: 0.625rem 1rem 0.625rem 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 2rem;
  position: sticky !important;
  top: 0;
  z-index: 1000;
  background: white;

  @include sm {
    padding: 10px 20px;
    gap: 1rem;
    flex-direction: column;
    .search-container {
      margin-left: -50px;
    }
  }

  img {
    width: 100px;
    cursor: pointer;
    padding: 0;
  }
  @include sm {
    // margin-left: -4rem;
  }

  .links-container {
    display: flex;
    flex-grow: 1;

    ul {
      display: flex;
      gap: 2rem;
      margin-left: 1rem;

      li {
        list-style: none;
        font-size: 1.1rem;

        :hover {
          color: #67c7b9;
        }
      }
    }
  }

  &_search {
    // border: 1px solid $grey;
    // border-radius: 30px;
    // height: 50px;
    padding: 7px 20px;
    display: flex;
    gap: 40px;
    // gap: 100px !important;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-left: 1.2rem;

    @include sm {
      gap: 0px !important;
      margin-left: 8%;
    }

    .input-heading {
      font-size: 13px;
      margin-bottom: 4px;
    }

    .dropdown-container {
      position: absolute;
      top: 5.2rem;
      left: 16rem;
      background: white;
      box-shadow: 0 0 10px gray;
      border-radius: 5px;
      width: 14rem;
      max-height: 15rem;
      overflow-y: scroll;

      .dropdown-section {
        padding: 8px;
        border-bottom: 1px solid #ccc;

        h4 {
          margin: 0;
          font-size: 14px;
          color: #67c7b9;
        }

        div {
          padding: 4px 0;
          cursor: pointer;

          &:hover {
            background-color: #f0f0f0;
            color: #67c7b9;
          }
        }
      }
    }
    .focused {
      background-color: #f0f0f0;
      color: #67c7b9;
    }

    .border {
      border: 1px solid gray;
      padding: 4px 11px;
      border-radius: 6px;
    }

    .search-btn {
      margin-top: 1.2rem;
      border: 1px solid white;
      padding: 6px 2rem;
      border-radius: 6px;
      background-color: #67c7b9;
      color: white;
    }

    .search-btn:hover {
      cursor: pointer;
      background-color: rgb(103, 199, 185, 0.6);
    }

    // &:hover {
    //   @include shadow;
    // }

    @include lg {
      display: none;
    }
  }

  &_right {
    display: flex;
    align-items: center;
    gap: 20px;

    .host {
      text-decoration: none;
      color: black;
      font-weight: 700;
      cursor: pointer;

      &:hover {
        color: #66cccc;
      }

      @include sm {
        display: none;
      }
    }

    &_account {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border: 1px solid $grey;
      border-radius: 30px;
      display: flex;
      gap: 10px;
      background-color: white;
      cursor: pointer;

      &:hover {
        @include shadow;

        svg {
          color: white;
        }
      }

      img {
        width: 40px;
        height: 40px;
        @include sm {
          display: none;
        }
      }
    }

    &_accountmenu {
      position: absolute;
      background-color: white;
      right: 60px;
      top: 80px;
      display: flex;
      flex-direction: column;
      width: 300px;
      padding: 10px 0;
      border: 1px solid $lightgrey;
      border-radius: 20px;
      margin-top: 1rem;
      @include shadow;
      z-index: 9999;
      padding: 8px 15px;

      .user-info {
        display: flex;
        flex-direction: column;
        gap: 4px;
        border-bottom: 1px solid rgb(191, 189, 189);
        padding-bottom: 10px;

        h2 {
          font-size: 16px;
          text-align: center;
          color: $blue;
        }
      }

      @include sm {
        right: 6px !important;
        top: 193px !important;
        margin-top: -1rem;
      }

      &-links {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        gap: 5px;
        a {
          width: 100%;
          text-decoration: none;
          color: $blue;
          font-weight: 700;
          padding: 8px 0 8px 3px;

          &:hover {
            color: #66cccc;
            background-color: rgba(217, 213, 213, 0.2);
          }
        }
      }
    }
  }
}

.guest-container {
  display: flex;
  flex-direction: column;
  .guest-value-container {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;

    .icons:hover {
      cursor: pointer;
      user-select: none;
    }
  }
}

.search-container {
  flex-grow: 1;
}

.search-popup {
  // background: rgba(155, 155, 155, 0.74);
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
  .container-1 {
    display: flex;
    flex-direction: column;
    z-index: 2000;

    .main-content {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 2rem;
      background-color: whitesmoke;
      border-radius: 5px;

      button {
        cursor: pointer;
        background-color: #66cccc;
        border: none;
        color: #fff;
        padding: 8px;
        transition: background-color 0.3s;

        &:hover {
          background-color: rgb(103, 199, 185, 0.5);
        }
      }

      .destination {
        display: flex;

        input {
          border-radius: 5px 0 0 5px;
          padding: 8px;
          outline: none;
          border: none;
        }

        .search-btn-container {
          display: flex;
          align-items: center;

          .search-btn {
            border-radius: 0 5px 5px 0;
          }
        }
      }
      .check-in,
      .check-out {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          border-radius: 5px;
          border: rgb(92, 91, 91);
          outline: rgb(92, 91, 91);
          padding: 8px;
        }
      }
      .guest {
        display: flex;
        justify-content: space-between;
        align-items: center;

        gap: 1rem;
        .label {
          min-width: 5rem;
        }
        .guest-value-container {
          display: flex;
          justify-content: space-between;
          flex-grow: 1;

          .guest-value {
            background-color: white;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 8px 0;
          }

          .guest-value-control {
            padding: 0 15px;
          }

          .guest-value-control:first-child {
            border-radius: 5px 0 0 5px;
          }
          .guest-value-control:last-child {
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }

    .search-close-btn {
      position: absolute;
      right: 1rem;
      top: 21%;
      box-shadow: none;
      background-color: white;
      width: fit-content;
      padding: 5px 8px;
      border-radius: 100%;
      transition: all 500ms ease-in-out;

      &:active {
        transform: scale(0.5);
      }

      .fa-xmark {
        display: block;
        font-size: 1.3rem;
        color: gray !important;
      }
    }
  }
}

.navbar_right00 {
  margin-left: -2rem;
}

@media (max-width: 768px) {
  .navbar {
    display: flex;
    position: sticky;
    padding: 0 2rem 1rem 2rem;
    margin: 0;
  }

  .navbar_right_account img {
    display: block;
  }

  #pc {
    display: none;
  }
  .search-container {
    display: flex;
    // justify-content: center;
    // margin: auto;
    width: 100%;

    #mob {
      display: flex;
      width: 90%;
      margin-right: 1rem;

      .input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.2);
        padding: 0.5rem 1.4rem;
        border-radius: 5%;
        margin-top: -1rem;
      }
    }

    .navbar_right00 {
      .mobHost {
        display: none;
      }

      .navbar_right_account {
        margin: -1rem -3.3rem 0 0;
      }
    }
  }

  .dropdown-container {
    position: absolute;
    background-color: white;
    top: 38%;
    padding: 8px;
    border-radius: 5px;
    max-height: 4.5rem;
    overflow-y: scroll;
    width: 60%;
  }
}

@media (min-width: 769px) {
  #mob {
    display: none;
  }
  #pc {
    display: flex;
  }
  .navbar_right00 {
    width: 25vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

@media only screen and (min-width: 1117px) and (max-width: 1420px) {
  .navbar {
    display: flex;
    margin: 0;

    &_search {
      margin: 0;

      padding: 5px 0;
      gap: 25px;
    }
  }
}
