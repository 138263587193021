.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.date-container {
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Adding box shadow */
}

.hotel-id {
  font-size: 20px;
  margin-bottom: 20px;
  /* Increased margin */
}

.calendar {
  margin-bottom: 20px;
}

.input-field-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.input-field-container label {
  margin-bottom: 10px;
}

.input-field-container input[type="number"] {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  /* Added width */
  transition: border-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Adding box shadow */
}

.input-field-container input[type="number"]:focus {
  outline: none;
  border-color: #007bff;
}

.input-field-container input[type="number"]::placeholder {
  color: #ccc;
}

.input-field-container input[type="number"]::-webkit-inner-spin-button,
.input-field-container input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-field-container input[type="number"]::-webkit-input-placeholder {
  color: #ccc;
}

.input-field-container input[type="number"]::-moz-placeholder {
  color: #ccc;
}

.input-field-container input[type="number"]:-ms-input-placeholder {
  color: #ccc;
}

button {
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Adding box shadow */
}

button:hover {
  background-color: #67c7b9;
}

;