@import "./variables.scss";
@import "./breakpoints.scss";

.register {
  width: 100vw;
  // height: 100vh;
  margin: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: url("../../public/assets/register.jpg") center;
  background-size: cover;

  &_content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 40%;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;

    @include lg {
      width: 60%;
    }

    @include mm {
      width: 80%;
    }

    &_form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      input {
        width: 100%;
        padding: 7px 15px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        outline: none;
        text-align: center;
        color: white;

        input:not(:placeholder-shown):focus {
          background: transparent; /* or background: none; */
        }

        &::placeholder {
          color: white;
        }
      }

      .password-input {
        width: 100%;
        position: relative;

        button {
          background-color: transparent;
          box-shadow: none;
          width: fit-content;
          position: absolute;
          top: -1.4rem;
          right: -10px;

          &:hover {
            background: none;
            box-shadow: none;
          }
        }
      }

      label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 9px;
        cursor: pointer;
        color: white;
        font-size: 14px;

        img {
          width: 25px;
        }
      }

      button {
        margin-top: 15px;
        min-width: 10rem;

        @include buttonStyle;
        width: 50%;

        &:hover {
          box-shadow: 0 0 10px 3px rgba(255, 255, 255);
        }
      }
    }

    a {
      text-decoration: none;
      color: white;
      font-size: 13px;
      margin-top: 10px;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.heading {
  display: flex;
  justify-content: center;
  color: white;
}
