.policy-container {
    margin-top: 1rem;
}

.policy-container .policy-main-heading {
    text-align: center;
    border-bottom: 1px solid gray;
    margin-bottom: 1.5rem;
}

.policy-container .sub-heading {
    color: #1d6c60d8;
    margin-bottom: 5px;
}

.policy-container .policy-content-container {
    text-align: justify;
}

.policy-container .policy-content {
    padding: 0 6rem;
    margin-bottom: 1rem;
    text-align: justify;

}

.policy-container .policy-list {
    margin-left: 1.2rem;
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.policy-container .policy-list .list-heading {
    color: #1d6c60;
    font-weight: 800;
}

.policies-container {
    padding: 0 6rem;
    text-align: justify;

}

.policies-container .policy {
    margin-bottom: 1rem;
}

@media (max-width: 780px) {
    .policy-container .policy-content {
        padding: 0 2rem;
        text-align: start;
    }

    .policies-container {
        padding: 0 2rem;
        text-align: start;
    }

    .policy-container .policy-main-heading {
        font-size: 1.5rem;
    }

    .policy-container .sub-heading {
        font-size: 1.2rem;
    }
}