.reset-page-container {
    background-color: rgba(0, 0, 0, 0.8);
    margin: auto;
    width: 40%;
    border-radius: 20px;
    margin-top: 2rem;
    padding: 30px 10px;
}

.reset-page-container h1 {
    color: white;
    text-align: center;
    padding-top: 20px;
    font-size: 24px;
    font-weight: 200;
}



.reset-page-password {
    display: flex;
    justify-content: center;
}

.reset-page-password input {
    position: relative;
    width: 100%;
    padding: 7px 15px;
    background-color: transparent;
    border: none;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.3); */
    border: gray 1px solid;
    outline: none;
    text-align: left;
    border-radius: 5px;
    width: 100%;
}

.reset-page-password button {
    box-shadow: none;
    width: fit-content;
    position: absolute;
    top: 13rem;
    left: 60rem;
    background-color: transparent;
}

@media (max-width: 480px) {
    .reset-page-container {
        width: 80%;
        padding: 0;
    }

    .reset-page-container h1 {
        font-size: 20px;
    }

    .update-password-btn {
        background-color: green;
        width: 100%;
    }
}